import React from "react";
import { Path, Svg } from "react-native-svg";
import styled from "styled-components/native";

const Credit = () => (
  <Svg width="129" height="48" viewBox="0 0 129 48" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2407 23.8395L1.88319 44.6259L0 43.6844L20.5439 2.45386L21.457 0.641997L21.7138 0L21.9991 0.641997L22.8979 2.45386L21.728 4.80785L13.3393 21.6424H30.1168L21.728 4.80785L22.8979 2.45386L32 20.6866L31.5149 21.6424L32 20.6866L41.0878 2.45386L42.272 4.80785L33.8689 21.6424H50.6607L42.272 4.80785L41.0878 2.45386L41.9866 0.641997L42.272 0.0142675L42.543 0.641997L64 43.6844L62.1168 44.6259L51.745 23.8395H33.5693L43.4561 43.6844L41.5729 44.6259L32 25.4088L22.4128 44.6259L20.5297 43.6844L30.4307 23.8395H12.2407Z"
      fill="#000"
    />
    <Path
      d="M69.28 12V0.656007H71.072V6.49601L76.416 0.640007H78.4L73.92 5.66401L78.656 12H76.656L72.816 6.76801L71.072 8.57601V12H69.28Z"
      fill="#000"
    />
    <Path
      d="M79.3785 12V3.63201H81.1385V12H79.3785ZM79.3785 2.25601V0.320007H81.1385V2.25601H79.3785Z"
      fill="#000"
    />
    <Path
      d="M87.6695 11.568C87.4455 11.6747 87.1362 11.7973 86.7415 11.936C86.3468 12.064 85.9255 12.128 85.4775 12.128C85.2002 12.128 84.9388 12.0907 84.6935 12.016C84.4482 11.9413 84.2295 11.8293 84.0375 11.68C83.8562 11.5307 83.7122 11.3387 83.6055 11.104C83.4988 10.8587 83.4455 10.5653 83.4455 10.224V4.99201H82.3415V3.63201H83.4455V0.880007H85.2055V3.63201H87.0295V4.99201H85.2055V9.66401C85.2269 9.96267 85.3175 10.1813 85.4775 10.32C85.6482 10.448 85.8562 10.512 86.1015 10.512C86.3468 10.512 86.5815 10.4693 86.8055 10.384C87.0295 10.2987 87.1948 10.2293 87.3015 10.176L87.6695 11.568Z"
      fill="#000"
    />
    <Path
      d="M93.3045 11.568C93.0805 11.6747 92.7712 11.7973 92.3765 11.936C91.9818 12.064 91.5605 12.128 91.1125 12.128C90.8352 12.128 90.5738 12.0907 90.3285 12.016C90.0832 11.9413 89.8645 11.8293 89.6725 11.68C89.4912 11.5307 89.3472 11.3387 89.2405 11.104C89.1339 10.8587 89.0805 10.5653 89.0805 10.224V4.99201H87.9765V3.63201H89.0805V0.880007H90.8405V3.63201H92.6645V4.99201H90.8405V9.66401C90.8619 9.96267 90.9525 10.1813 91.1125 10.32C91.2832 10.448 91.4912 10.512 91.7365 10.512C91.9818 10.512 92.2165 10.4693 92.4405 10.384C92.6645 10.2987 92.8298 10.2293 92.9365 10.176L93.3045 11.568Z"
      fill="#000"
    />
    <Path
      d="M97.8724 12.16C97.2217 12.16 96.6297 12.048 96.0964 11.824C95.5631 11.5893 95.1044 11.2747 94.7204 10.88C94.3471 10.4853 94.0537 10.0267 93.8404 9.50401C93.6377 8.98134 93.5364 8.42667 93.5364 7.84001C93.5364 7.25334 93.6377 6.69867 93.8404 6.17601C94.0537 5.64267 94.3471 5.17867 94.7204 4.78401C95.1044 4.37867 95.5631 4.06401 96.0964 3.84001C96.6297 3.60534 97.2271 3.48801 97.8884 3.48801C98.5391 3.48801 99.1257 3.60534 99.6484 3.84001C100.182 4.07467 100.635 4.38934 101.008 4.78401C101.382 5.16801 101.664 5.62134 101.856 6.14401C102.059 6.65601 102.16 7.19467 102.16 7.76001C102.16 7.88801 102.155 8.01067 102.144 8.12801C102.134 8.23467 102.123 8.32534 102.112 8.40001H95.4084C95.4404 8.77334 95.5257 9.10934 95.6644 9.40801C95.8137 9.70667 96.0004 9.96801 96.2244 10.192C96.4591 10.4053 96.7204 10.5707 97.0084 10.688C97.3071 10.8053 97.6164 10.864 97.9364 10.864C98.1711 10.864 98.4004 10.8373 98.6244 10.784C98.8591 10.72 99.0724 10.6347 99.2644 10.528C99.4564 10.4107 99.6271 10.272 99.7764 10.112C99.9257 9.95201 100.043 9.77601 100.128 9.58401L101.632 10.016C101.344 10.6453 100.864 11.1627 100.192 11.568C99.5311 11.9627 98.7577 12.16 97.8724 12.16ZM100.416 7.18401C100.384 6.83201 100.294 6.50667 100.144 6.20801C100.006 5.90934 99.8244 5.65867 99.6004 5.45601C99.3764 5.24267 99.1151 5.07734 98.8164 4.96001C98.5177 4.84267 98.2031 4.78401 97.8724 4.78401C97.5417 4.78401 97.2271 4.84267 96.9284 4.96001C96.6404 5.07734 96.3844 5.24267 96.1604 5.45601C95.9364 5.65867 95.7551 5.90934 95.6164 6.20801C95.4777 6.50667 95.3924 6.83201 95.3604 7.18401H100.416Z"
      fill="#000"
    />
    <Path
      d="M110.86 12H109.1V7.31201C109.1 6.52267 108.972 5.95201 108.716 5.60001C108.471 5.23734 108.103 5.05601 107.612 5.05601C107.356 5.05601 107.1 5.10401 106.844 5.20001C106.588 5.29601 106.348 5.43467 106.124 5.61601C105.9 5.78667 105.697 5.99467 105.516 6.24001C105.345 6.47467 105.212 6.73601 105.116 7.02401V12H103.356V3.63201H104.956V5.31201C105.276 4.74667 105.74 4.30401 106.348 3.98401C106.956 3.65334 107.628 3.48801 108.364 3.48801C108.865 3.48801 109.276 3.57867 109.596 3.76001C109.916 3.94134 110.167 4.19201 110.348 4.51201C110.54 4.82134 110.673 5.18401 110.748 5.60001C110.823 6.00534 110.86 6.43734 110.86 6.89601V12Z"
      fill="#000"
    />
    <Path
      d="M69.28 28V16.64H73.312C74.2293 16.64 75.0293 16.7893 75.712 17.088C76.4053 17.3867 76.9813 17.792 77.44 18.304C77.9093 18.816 78.2613 19.4187 78.496 20.112C78.7307 20.7947 78.848 21.5253 78.848 22.304C78.848 23.168 78.7147 23.952 78.448 24.656C78.192 25.3493 77.824 25.9467 77.344 26.448C76.864 26.9387 76.2827 27.3227 75.6 27.6C74.9173 27.8667 74.1547 28 73.312 28H69.28ZM77.024 22.304C77.024 21.7067 76.9387 21.1573 76.768 20.656C76.608 20.1547 76.368 19.728 76.048 19.376C75.728 19.0133 75.3387 18.7307 74.88 18.528C74.4213 18.3253 73.8987 18.224 73.312 18.224H71.072V26.416H73.312C73.9093 26.416 74.4373 26.3147 74.896 26.112C75.3653 25.8987 75.7547 25.6107 76.064 25.248C76.384 24.8747 76.624 24.4373 76.784 23.936C76.944 23.4347 77.024 22.8907 77.024 22.304Z"
      fill="#000"
    />
    <Path
      d="M80.2691 28V19.632H82.0291V28H80.2691ZM80.2691 18.256V16.32H82.0291V18.256H80.2691Z"
      fill="#000"
    />
    <Path
      d="M87.2161 28.08C86.6508 28.08 86.1335 27.968 85.6641 27.744C85.1948 27.5093 84.7895 27.1947 84.4481 26.8C84.1175 26.4053 83.8561 25.952 83.6641 25.44C83.4828 24.928 83.3921 24.3893 83.3921 23.824C83.3921 23.2267 83.4881 22.6667 83.6801 22.144C83.8721 21.6107 84.1388 21.152 84.4801 20.768C84.8215 20.3733 85.2268 20.064 85.6961 19.84C86.1761 19.6053 86.7041 19.488 87.2801 19.488C87.9521 19.488 88.5388 19.648 89.0401 19.968C89.5521 20.288 89.9735 20.704 90.3041 21.216V19.632H91.8561V27.76C91.8561 28.368 91.7441 28.9067 91.5201 29.376C91.2961 29.8453 90.9815 30.24 90.5761 30.56C90.1815 30.8907 89.7121 31.1413 89.1681 31.312C88.6241 31.4827 88.0375 31.568 87.4081 31.568C86.4801 31.568 85.7068 31.408 85.0881 31.088C84.4801 30.7787 83.9681 30.3413 83.5521 29.776L84.5441 28.848C84.8641 29.296 85.2748 29.6373 85.7761 29.872C86.2881 30.1067 86.8321 30.224 87.4081 30.224C87.7708 30.224 88.1121 30.176 88.4321 30.08C88.7628 29.984 89.0508 29.8347 89.2961 29.632C89.5415 29.4293 89.7335 29.1733 89.8721 28.864C90.0215 28.5547 90.0961 28.1867 90.0961 27.76V26.48C89.7975 26.992 89.3868 27.3867 88.8641 27.664C88.3415 27.9413 87.7921 28.08 87.2161 28.08ZM87.7921 26.656C88.0588 26.656 88.3148 26.6133 88.5601 26.528C88.8055 26.432 89.0295 26.3093 89.2321 26.16C89.4455 26 89.6268 25.824 89.7761 25.632C89.9255 25.4293 90.0321 25.216 90.0961 24.992V22.8C89.9895 22.5333 89.8508 22.2933 89.6801 22.08C89.5095 21.856 89.3121 21.664 89.0881 21.504C88.8748 21.344 88.6455 21.2213 88.4001 21.136C88.1548 21.04 87.9095 20.992 87.6641 20.992C87.2801 20.992 86.9335 21.0773 86.6241 21.248C86.3255 21.408 86.0695 21.6267 85.8561 21.904C85.6428 22.1707 85.4775 22.4747 85.3601 22.816C85.2428 23.1573 85.1841 23.5093 85.1841 23.872C85.1841 24.256 85.2481 24.6187 85.3761 24.96C85.5148 25.2907 85.7015 25.584 85.9361 25.84C86.1708 26.0853 86.4428 26.2827 86.7521 26.432C87.0721 26.5813 87.4188 26.656 87.7921 26.656Z"
      fill="#000"
    />
    <Path
      d="M93.7266 28V19.632H95.4866V28H93.7266ZM93.7266 18.256V16.32H95.4866V18.256H93.7266Z"
      fill="#000"
    />
    <Path
      d="M102.018 27.568C101.794 27.6747 101.484 27.7973 101.09 27.936C100.695 28.064 100.274 28.128 99.8256 28.128C99.5483 28.128 99.287 28.0907 99.0416 28.016C98.7963 27.9413 98.5776 27.8293 98.3856 27.68C98.2043 27.5307 98.0603 27.3387 97.9536 27.104C97.847 26.8587 97.7936 26.5653 97.7936 26.224V20.992H96.6896V19.632H97.7936V16.88H99.5536V19.632H101.378V20.992H99.5536V25.664C99.575 25.9627 99.6656 26.1813 99.8256 26.32C99.9963 26.448 100.204 26.512 100.45 26.512C100.695 26.512 100.93 26.4693 101.154 26.384C101.378 26.2987 101.543 26.2293 101.65 26.176L102.018 27.568Z"
      fill="#000"
    />
    <Path
      d="M105.221 28.16C104.815 28.16 104.437 28.096 104.085 27.968C103.743 27.8293 103.445 27.6427 103.189 27.408C102.943 27.1733 102.746 26.896 102.597 26.576C102.458 26.256 102.389 25.9093 102.389 25.536C102.389 25.1413 102.474 24.784 102.645 24.464C102.815 24.144 103.055 23.8667 103.365 23.632C103.685 23.3973 104.058 23.216 104.485 23.088C104.922 22.96 105.402 22.896 105.925 22.896C106.319 22.896 106.709 22.9333 107.093 23.008C107.477 23.072 107.823 23.1627 108.133 23.28V22.704C108.133 22.0853 107.957 21.6053 107.605 21.264C107.253 20.912 106.746 20.736 106.085 20.736C105.637 20.736 105.194 20.8213 104.757 20.992C104.33 21.152 103.887 21.3867 103.429 21.696L102.885 20.576C103.962 19.8507 105.082 19.488 106.245 19.488C107.397 19.488 108.293 19.7813 108.933 20.368C109.573 20.9547 109.893 21.7973 109.893 22.896V25.92C109.893 26.2933 110.047 26.4853 110.357 26.496V28C110.186 28.032 110.037 28.0533 109.909 28.064C109.791 28.0747 109.674 28.08 109.557 28.08C109.215 28.08 108.949 27.9947 108.757 27.824C108.575 27.6533 108.463 27.4347 108.421 27.168L108.373 26.64C107.999 27.1307 107.535 27.5093 106.981 27.776C106.437 28.032 105.85 28.16 105.221 28.16ZM105.701 26.864C106.127 26.864 106.527 26.7893 106.901 26.64C107.285 26.48 107.583 26.2613 107.797 25.984C108.021 25.792 108.133 25.5893 108.133 25.376V24.272C107.834 24.1547 107.514 24.064 107.173 24C106.842 23.936 106.517 23.904 106.197 23.904C105.567 23.904 105.05 24.0427 104.645 24.32C104.25 24.5867 104.053 24.944 104.053 25.392C104.053 25.808 104.207 26.16 104.517 26.448C104.826 26.7253 105.221 26.864 105.701 26.864Z"
      fill="#000"
    />
    <Path
      d="M111.804 16.32H113.564V25.584C113.564 25.8933 113.644 26.1387 113.804 26.32C113.964 26.4907 114.188 26.576 114.476 26.576C114.593 26.576 114.727 26.5547 114.876 26.512C115.036 26.4693 115.185 26.4213 115.324 26.368L115.58 27.744C115.324 27.8613 115.02 27.952 114.668 28.016C114.316 28.0907 114.001 28.128 113.724 28.128C113.116 28.128 112.641 27.9627 112.3 27.632C111.969 27.3013 111.804 26.832 111.804 26.224V16.32Z"
      fill="#000"
    />
    <Path
      d="M76 35.312C75.8933 35.1947 75.7387 35.072 75.536 34.944C75.3333 34.8053 75.0933 34.6773 74.816 34.56C74.5493 34.4427 74.2507 34.3467 73.92 34.272C73.6 34.1973 73.2693 34.16 72.928 34.16C72.1707 34.16 71.6107 34.2987 71.248 34.576C70.896 34.8533 70.72 35.2373 70.72 35.728C70.72 36.0053 70.7733 36.2347 70.88 36.416C70.9973 36.5867 71.1733 36.7413 71.408 36.88C71.6427 37.008 71.936 37.1253 72.288 37.232C72.64 37.328 73.0507 37.4347 73.52 37.552C74.096 37.6907 74.6133 37.8453 75.072 38.016C75.5413 38.1867 75.936 38.3947 76.256 38.64C76.576 38.8853 76.8213 39.1893 76.992 39.552C77.1733 39.904 77.264 40.336 77.264 40.848C77.264 41.424 77.152 41.92 76.928 42.336C76.7147 42.752 76.416 43.0933 76.032 43.36C75.6587 43.616 75.2213 43.808 74.72 43.936C74.2187 44.0533 73.68 44.112 73.104 44.112C72.24 44.112 71.4027 43.984 70.592 43.728C69.792 43.4613 69.0667 43.0773 68.416 42.576L69.232 41.04C69.3707 41.1787 69.568 41.3333 69.824 41.504C70.0907 41.6747 70.3947 41.8347 70.736 41.984C71.088 42.1227 71.4667 42.2453 71.872 42.352C72.2773 42.448 72.6987 42.496 73.136 42.496C73.84 42.496 74.384 42.3733 74.768 42.128C75.152 41.8827 75.344 41.52 75.344 41.04C75.344 40.752 75.2693 40.512 75.12 40.32C74.9813 40.128 74.7787 39.9627 74.512 39.824C74.2453 39.6747 73.92 39.5413 73.536 39.424C73.152 39.3067 72.7147 39.184 72.224 39.056C71.6587 38.9067 71.1627 38.752 70.736 38.592C70.32 38.4213 69.9733 38.2187 69.696 37.984C69.4187 37.7493 69.2053 37.4773 69.056 37.168C68.9173 36.848 68.848 36.464 68.848 36.016C68.848 35.4613 68.9547 34.9707 69.168 34.544C69.3813 34.1067 69.6693 33.744 70.032 33.456C70.4053 33.1573 70.8427 32.9333 71.344 32.784C71.8453 32.6347 72.3893 32.56 72.976 32.56C73.744 32.56 74.4533 32.6827 75.104 32.928C75.7547 33.1733 76.3253 33.4773 76.816 33.84L76 35.312Z"
      fill="#000"
    />
    <Path
      d="M78.5973 44V35.632H80.3573V44H78.5973ZM78.5973 34.256V32.32H80.3573V34.256H78.5973Z"
      fill="#000"
    />
    <Path
      d="M85.5443 44.08C84.9789 44.08 84.4616 43.968 83.9923 43.744C83.5229 43.5093 83.1176 43.1947 82.7763 42.8C82.4456 42.4053 82.1843 41.952 81.9923 41.44C81.8109 40.928 81.7203 40.3893 81.7203 39.824C81.7203 39.2267 81.8163 38.6667 82.0083 38.144C82.2003 37.6107 82.4669 37.152 82.8083 36.768C83.1496 36.3733 83.5549 36.064 84.0243 35.84C84.5043 35.6053 85.0323 35.488 85.6083 35.488C86.2803 35.488 86.8669 35.648 87.3683 35.968C87.8803 36.288 88.3016 36.704 88.6323 37.216V35.632H90.1843V43.76C90.1843 44.368 90.0723 44.9067 89.8483 45.376C89.6243 45.8453 89.3096 46.24 88.9043 46.56C88.5096 46.8907 88.0403 47.1413 87.4963 47.312C86.9523 47.4827 86.3656 47.568 85.7363 47.568C84.8083 47.568 84.0349 47.408 83.4163 47.088C82.8083 46.7787 82.2963 46.3413 81.8803 45.776L82.8723 44.848C83.1923 45.296 83.6029 45.6373 84.1043 45.872C84.6163 46.1067 85.1603 46.224 85.7363 46.224C86.0989 46.224 86.4403 46.176 86.7603 46.08C87.0909 45.984 87.3789 45.8347 87.6243 45.632C87.8696 45.4293 88.0616 45.1733 88.2003 44.864C88.3496 44.5547 88.4243 44.1867 88.4243 43.76V42.48C88.1256 42.992 87.7149 43.3867 87.1923 43.664C86.6696 43.9413 86.1203 44.08 85.5443 44.08ZM86.1203 42.656C86.3869 42.656 86.6429 42.6133 86.8883 42.528C87.1336 42.432 87.3576 42.3093 87.5603 42.16C87.7736 42 87.9549 41.824 88.1043 41.632C88.2536 41.4293 88.3603 41.216 88.4243 40.992V38.8C88.3176 38.5333 88.1789 38.2933 88.0083 38.08C87.8376 37.856 87.6403 37.664 87.4163 37.504C87.2029 37.344 86.9736 37.2213 86.7283 37.136C86.4829 37.04 86.2376 36.992 85.9923 36.992C85.6083 36.992 85.2616 37.0773 84.9523 37.248C84.6536 37.408 84.3976 37.6267 84.1843 37.904C83.9709 38.1707 83.8056 38.4747 83.6883 38.816C83.5709 39.1573 83.5123 39.5093 83.5123 39.872C83.5123 40.256 83.5763 40.6187 83.7043 40.96C83.8429 41.2907 84.0296 41.584 84.2643 41.84C84.4989 42.0853 84.7709 42.2827 85.0803 42.432C85.4003 42.5813 85.7469 42.656 86.1203 42.656Z"
      fill="#000"
    />
    <Path
      d="M99.5588 44H97.7988V39.312C97.7988 38.5227 97.6708 37.952 97.4148 37.6C97.1694 37.2373 96.8014 37.056 96.3108 37.056C96.0548 37.056 95.7988 37.104 95.5428 37.2C95.2868 37.296 95.0468 37.4347 94.8228 37.616C94.5988 37.7867 94.3961 37.9947 94.2148 38.24C94.0441 38.4747 93.9108 38.736 93.8148 39.024V44H92.0548V35.632H93.6548V37.312C93.9748 36.7467 94.4388 36.304 95.0468 35.984C95.6548 35.6533 96.3268 35.488 97.0628 35.488C97.5641 35.488 97.9748 35.5787 98.2948 35.76C98.6148 35.9413 98.8654 36.192 99.0468 36.512C99.2388 36.8213 99.3721 37.184 99.4468 37.6C99.5214 38.0053 99.5588 38.4373 99.5588 38.896V44Z"
      fill="#000"
    />
    <Path
      d="M103.586 44.16C103.18 44.16 102.802 44.096 102.45 43.968C102.108 43.8293 101.81 43.6427 101.554 43.408C101.308 43.1733 101.111 42.896 100.962 42.576C100.823 42.256 100.754 41.9093 100.754 41.536C100.754 41.1413 100.839 40.784 101.01 40.464C101.18 40.144 101.42 39.8667 101.73 39.632C102.05 39.3973 102.423 39.216 102.85 39.088C103.287 38.96 103.767 38.896 104.29 38.896C104.684 38.896 105.074 38.9333 105.458 39.008C105.842 39.072 106.188 39.1627 106.498 39.28V38.704C106.498 38.0853 106.322 37.6053 105.97 37.264C105.618 36.912 105.111 36.736 104.45 36.736C104.002 36.736 103.559 36.8213 103.122 36.992C102.695 37.152 102.252 37.3867 101.794 37.696L101.25 36.576C102.327 35.8507 103.447 35.488 104.61 35.488C105.762 35.488 106.658 35.7813 107.298 36.368C107.938 36.9547 108.258 37.7973 108.258 38.896V41.92C108.258 42.2933 108.412 42.4853 108.722 42.496V44C108.551 44.032 108.402 44.0533 108.274 44.064C108.156 44.0747 108.039 44.08 107.922 44.08C107.58 44.08 107.314 43.9947 107.122 43.824C106.94 43.6533 106.828 43.4347 106.786 43.168L106.738 42.64C106.364 43.1307 105.9 43.5093 105.346 43.776C104.802 44.032 104.215 44.16 103.586 44.16ZM104.066 42.864C104.492 42.864 104.892 42.7893 105.266 42.64C105.65 42.48 105.948 42.2613 106.162 41.984C106.386 41.792 106.498 41.5893 106.498 41.376V40.272C106.199 40.1547 105.879 40.064 105.538 40C105.207 39.936 104.882 39.904 104.562 39.904C103.932 39.904 103.415 40.0427 103.01 40.32C102.615 40.5867 102.418 40.944 102.418 41.392C102.418 41.808 102.572 42.16 102.882 42.448C103.191 42.7253 103.586 42.864 104.066 42.864Z"
      fill="#000"
    />
    <Path
      d="M113.465 44.08C112.9 44.08 112.382 43.968 111.913 43.744C111.444 43.5093 111.038 43.1947 110.697 42.8C110.366 42.4053 110.105 41.952 109.913 41.44C109.732 40.928 109.641 40.3893 109.641 39.824C109.641 39.2267 109.737 38.6667 109.929 38.144C110.121 37.6107 110.388 37.152 110.729 36.768C111.07 36.3733 111.476 36.064 111.945 35.84C112.425 35.6053 112.953 35.488 113.529 35.488C114.201 35.488 114.788 35.648 115.289 35.968C115.801 36.288 116.222 36.704 116.553 37.216V35.632H118.105V43.76C118.105 44.368 117.993 44.9067 117.769 45.376C117.545 45.8453 117.23 46.24 116.825 46.56C116.43 46.8907 115.961 47.1413 115.417 47.312C114.873 47.4827 114.286 47.568 113.657 47.568C112.729 47.568 111.956 47.408 111.337 47.088C110.729 46.7787 110.217 46.3413 109.801 45.776L110.793 44.848C111.113 45.296 111.524 45.6373 112.025 45.872C112.537 46.1067 113.081 46.224 113.657 46.224C114.02 46.224 114.361 46.176 114.681 46.08C115.012 45.984 115.3 45.8347 115.545 45.632C115.79 45.4293 115.982 45.1733 116.121 44.864C116.27 44.5547 116.345 44.1867 116.345 43.76V42.48C116.046 42.992 115.636 43.3867 115.113 43.664C114.59 43.9413 114.041 44.08 113.465 44.08ZM114.041 42.656C114.308 42.656 114.564 42.6133 114.809 42.528C115.054 42.432 115.278 42.3093 115.481 42.16C115.694 42 115.876 41.824 116.025 41.632C116.174 41.4293 116.281 41.216 116.345 40.992V38.8C116.238 38.5333 116.1 38.2933 115.929 38.08C115.758 37.856 115.561 37.664 115.337 37.504C115.124 37.344 114.894 37.2213 114.649 37.136C114.404 37.04 114.158 36.992 113.913 36.992C113.529 36.992 113.182 37.0773 112.873 37.248C112.574 37.408 112.318 37.6267 112.105 37.904C111.892 38.1707 111.726 38.4747 111.609 38.816C111.492 39.1573 111.433 39.5093 111.433 39.872C111.433 40.256 111.497 40.6187 111.625 40.96C111.764 41.2907 111.95 41.584 112.185 41.84C112.42 42.0853 112.692 42.2827 113.001 42.432C113.321 42.5813 113.668 42.656 114.041 42.656Z"
      fill="#000"
    />
    <Path
      d="M123.767 44.16C123.117 44.16 122.525 44.048 121.991 43.824C121.458 43.5893 120.999 43.2747 120.615 42.88C120.242 42.4853 119.949 42.0267 119.735 41.504C119.533 40.9813 119.431 40.4267 119.431 39.84C119.431 39.2533 119.533 38.6987 119.735 38.176C119.949 37.6427 120.242 37.1787 120.615 36.784C120.999 36.3787 121.458 36.064 121.991 35.84C122.525 35.6053 123.122 35.488 123.783 35.488C124.434 35.488 125.021 35.6053 125.543 35.84C126.077 36.0747 126.53 36.3893 126.903 36.784C127.277 37.168 127.559 37.6213 127.751 38.144C127.954 38.656 128.055 39.1947 128.055 39.76C128.055 39.888 128.05 40.0107 128.039 40.128C128.029 40.2347 128.018 40.3253 128.007 40.4H121.303C121.335 40.7733 121.421 41.1093 121.559 41.408C121.709 41.7067 121.895 41.968 122.119 42.192C122.354 42.4053 122.615 42.5707 122.903 42.688C123.202 42.8053 123.511 42.864 123.831 42.864C124.066 42.864 124.295 42.8373 124.519 42.784C124.754 42.72 124.967 42.6347 125.159 42.528C125.351 42.4107 125.522 42.272 125.671 42.112C125.821 41.952 125.938 41.776 126.023 41.584L127.527 42.016C127.239 42.6453 126.759 43.1627 126.087 43.568C125.426 43.9627 124.653 44.16 123.767 44.16ZM126.311 39.184C126.279 38.832 126.189 38.5067 126.039 38.208C125.901 37.9093 125.719 37.6587 125.495 37.456C125.271 37.2427 125.01 37.0773 124.711 36.96C124.413 36.8427 124.098 36.784 123.767 36.784C123.437 36.784 123.122 36.8427 122.823 36.96C122.535 37.0773 122.279 37.2427 122.055 37.456C121.831 37.6587 121.65 37.9093 121.511 38.208C121.373 38.5067 121.287 38.832 121.255 39.184H126.311Z"
      fill="#000"
    />
  </Svg>
);

export default Credit;

export const CreditWidgetContainer = styled.View`
  position: absolute;
  right: 0;
  bottom: 0;
`;
