import React from "react";
import { Path, Svg } from "react-native-svg";

const Thunderstorm = () => (
  <Svg width="107" height="118" viewBox="0 0 107 118" fill="none">
    <Path
      d="M52.6652 117.33L65.5981 102.664L54.9318 97.3306L69.998 79.9977H80.6644L67.7314 94.664L78.3978 99.9972L63.3315 117.33H52.6652ZM20.6661 117.33L33.599 102.664L22.9327 97.3306L37.9989 79.9977H48.6653L35.7323 94.664L46.3987 99.9972L31.3324 117.33H20.6661ZM27.9992 71.998C20.2661 71.998 13.6663 69.2647 8.19977 63.7982C2.73326 58.3317 0 51.7319 0 43.9988C0 36.9767 2.51104 30.7102 7.53312 25.1993C12.5552 19.6883 18.7995 16.6662 26.2659 16.1329C29.1103 11.1552 32.8657 7.22202 37.5323 4.33321C42.1988 1.4444 47.4653 0 53.3318 0C61.4205 0 68.1981 2.55548 73.6646 7.66645C79.1311 12.7774 82.4866 19.1106 83.731 26.6659C90.753 27.0215 96.3306 29.3992 100.464 33.799C104.597 38.1989 106.664 43.3765 106.664 49.3319C106.664 55.554 104.464 60.8872 100.064 65.3315C95.664 69.7758 90.3085 71.998 83.9976 71.998H27.9992ZM27.9992 63.9982H83.9976C88.0864 63.9982 91.553 62.5538 94.3973 59.665C97.2417 56.7762 98.6639 53.2874 98.6639 49.1986C98.6639 45.1987 97.2417 41.7766 94.3973 38.9322C91.553 36.0879 88.0864 34.6657 83.9976 34.6657H75.9978V30.6658C75.9978 24.3549 73.7979 18.9995 69.398 14.5996C64.9982 10.1997 59.6428 7.99977 53.3318 7.99977C48.7986 7.99977 44.6432 9.22196 40.8655 11.6663C37.0878 14.1107 34.3101 17.4217 32.5324 21.5994L31.4658 23.9993H27.7325C22.2216 24.1771 17.5551 26.1993 13.7329 30.0658C9.91083 33.9324 7.99977 38.5767 7.99977 43.9988C7.99977 49.5097 9.95527 54.2207 13.8663 58.1317C17.7773 62.0427 22.4883 63.9982 27.9992 63.9982Z"
      fill="#000"
    />
  </Svg>
);

export default Thunderstorm;
