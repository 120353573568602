import React from "react";
import { Path, Svg } from "react-native-svg";

const Cloudy = () => (
  <Svg width="118" height="86" viewBox="0 0 118 86" fill="none">
    <Path
      d="M28.1325 85.2476C20.3105 85.2476 13.6663 82.5143 8.19977 77.0478C2.73326 71.5813 0 64.9371 0 57.1151C0 50.1819 2.22216 44.0932 6.66648 38.8489C11.1108 33.6046 16.7551 30.4491 23.5993 29.3825C25.3771 20.7605 29.5547 13.7163 36.1323 8.24977C42.7099 2.78326 50.3097 0.0500031 58.9317 0.0500031C68.8869 0.0500031 77.2867 3.67212 84.1309 10.9164C90.9752 18.1606 94.3973 26.8492 94.3973 36.9823V40.1822C100.797 40.0044 106.219 42.071 110.664 46.382C115.108 50.693 117.33 56.1373 117.33 62.7149C117.33 68.848 115.108 74.1368 110.664 78.5811C106.219 83.0254 100.93 85.2476 94.7973 85.2476H28.1325ZM28.1325 77.2478H94.7973C98.7972 77.2478 102.219 75.8256 105.064 72.9813C107.908 70.1369 109.33 66.7148 109.33 62.7149C109.33 58.715 107.908 55.2929 105.064 52.4485C102.219 49.6042 98.7972 48.182 94.7973 48.182H86.3976V36.9823C86.3976 28.8936 83.6865 22.0494 78.2644 16.4495C72.8424 10.8497 66.2203 8.04978 58.3983 8.04978C50.5763 8.04978 43.9321 10.8497 38.4656 16.4495C32.9991 22.0494 30.2658 28.8936 30.2658 36.9823H27.7325C22.2216 36.9823 17.5551 38.9156 13.7329 42.7821C9.91083 46.6487 7.99977 51.4263 7.99977 57.1151C7.99977 62.7149 9.95527 67.4703 13.8663 71.3813C17.7773 75.2923 22.5327 77.2478 28.1325 77.2478Z"
      fill="#000"
    />
  </Svg>
);

export default Cloudy;
