import React from "react";
import { Path, Svg } from "react-native-svg";

const Wind = () => (
  <Svg width="19" height="16" viewBox="0 0 19 16" fill="none">
    <Path
      d="M9.05882 16C8.21177 16 7.54118 15.7804 7.04706 15.3412C6.55294 14.902 6.30588 14.2353 6.30588 13.3412H7.90588C7.90588 13.749 7.99608 14.0588 8.17647 14.2706C8.35686 14.4824 8.65098 14.5882 9.05882 14.5882C9.48235 14.5882 9.78431 14.4941 9.96471 14.3059C10.1451 14.1176 10.2353 13.7961 10.2353 13.3412C10.2353 12.8863 10.1451 12.5529 9.96471 12.3412C9.78431 12.1294 9.48235 12.0235 9.05882 12.0235H0V10.6118H9.05882C9.90588 10.6118 10.549 10.8314 10.9882 11.2706C11.4275 11.7098 11.6471 12.4 11.6471 13.3412C11.6471 14.2353 11.4275 14.902 10.9882 15.3412C10.549 15.7804 9.90588 16 9.05882 16ZM0 6.4V4.98824H12.8941C13.4745 4.98824 13.898 4.85098 14.1647 4.57647C14.4314 4.30196 14.5647 3.84314 14.5647 3.2C14.5647 2.55686 14.4314 2.09804 14.1647 1.82353C13.898 1.54902 13.4745 1.41176 12.8941 1.41176C12.298 1.41176 11.8667 1.57255 11.6 1.89412C11.3333 2.21569 11.2 2.61961 11.2 3.10588H9.78824C9.78824 2.19608 10.0627 1.45098 10.6118 0.870588C11.1608 0.290196 11.9216 0 12.8941 0C13.851 0 14.6039 0.278431 15.1529 0.835294C15.702 1.39216 15.9765 2.18039 15.9765 3.2C15.9765 4.21961 15.702 5.00784 15.1529 5.56471C14.6039 6.12157 13.851 6.4 12.8941 6.4H0ZM15.8118 14.1647V12.7529C16.3608 12.7529 16.7647 12.6 17.0235 12.2941C17.2824 11.9882 17.4118 11.5451 17.4118 10.9647C17.4118 10.3686 17.2667 9.93725 16.9765 9.67059C16.6863 9.40392 16.2667 9.27059 15.7176 9.27059H0V7.85882H15.7176C16.6902 7.85882 17.451 8.13333 18 8.68235C18.549 9.23137 18.8235 9.99216 18.8235 10.9647C18.8235 11.9686 18.5647 12.7529 18.0471 13.3176C17.5294 13.8824 16.7843 14.1647 15.8118 14.1647V14.1647Z"
      fill="#000"
    />
  </Svg>
);

export default Wind;
